import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/about-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`“Todos en este país deberían aprender a programar una computadora, porque te enseña a pensar.” `}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`Steve Jobs`}</li>
      </ul>
    </blockquote>
    <p>{`Cuando salieron al mercado las primeras computadoras personales fueron creadas para aficionados a la computación. Para poder utilizarlas era necesario un conocimiento avanzado de programación.`}</p>
    <p>{`Hoy en día no es necesario saber programar para utilizar nuestras computadoras, ya que las computadoras son diseñadas y creadas para todo tipo de usuarios. Existe un concepto en diseño y desarrollo de software llamado usabilidad el cual tiene como propósito crear las interfaces súper fáciles de usar, inclusive para niños menores 5 años.`}</p>
    <p>{`Por esta razón hoy en día menos del 10 por ciento de los profesionales saben programar, aún cuando el 100 por ciento de estos utiliza una computadora para su profesión.`}</p>
    <p>{`Esto significa que todos estos profesionales requieren de programas creados por terceros para hacer tareas muy sencillas como crear un formulario para satisfacción de clientes, o un sistema de administración de clientes o cualquier sistema para automatizar procesos, para lo cual las computadoras fueron creadas.`}</p>
    <p>{`Al utilizar una computadora sin saber programar utilizamos apenas una pequeña parte de la misma y se convierte en una herramienta hueca la cual necesita de software creados por terceros que muchas veces no comprenden nuestra labor.`}</p>
    <p>{`¿No sería mejor engrandecer nuestras profesiones creando software que nos sirva a nosotros mismos y con la cual podamos mejorar nuestras propias vidas utilizando la herramienta que más utilizamos?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      